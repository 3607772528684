/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledVideo = styled.section`

`

interface VideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_VideoProps
}

const Video: React.FC<VideoProps> = ({ fields }) => (
  <StyledVideo>
    <div className="container">
      <div className="row pb-5">
        <div className="col-md-8">
          <iframe 
            src={fields.video} 
            width="100%" 
            height="315" 
            title="Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            />
        </div>
      </div>
    </div>
  </StyledVideo>
)

export default Video
